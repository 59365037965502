@import "@variables"; @import "@mixin";
.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black-2, 0.6);
  z-index: 5;
}
