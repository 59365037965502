@import "@variables"; @import "@mixin";
$starSize: 15px;
$starHalf: $starSize / 2;

$rate1-1: $starSize + $starHalf - 6.4;
$rate1-2: $starSize + $starHalf - 4.8;
$rate1-3: $starSize + $starHalf - 3.2;
$rate1-4: $starSize + $starHalf - 1.6;
$rate1-5: $starSize + $starHalf;
$rate1-6: $starSize + $starHalf + 1.6;
$rate1-7: $starSize + $starHalf + 3.2;
$rate1-8: $starSize + $starHalf + 4.8;
$rate1-9: $starSize + $starHalf + 6.4;
$rate2-1: ($starSize * 2) + $starHalf - 6.4;
$rate2-2: ($starSize * 2) + $starHalf - 4.8;
$rate2-3: ($starSize * 2) + $starHalf - 3.2;
$rate2-4: ($starSize * 2) + $starHalf - 1.6;
$rate2-5: ($starSize * 2) + $starHalf;
$rate2-6: ($starSize * 2) + $starHalf + 1.6;
$rate2-7: ($starSize * 2) + $starHalf + 3.2;
$rate2-8: ($starSize * 2) + $starHalf + 4.8;
$rate2-9: ($starSize * 2) + $starHalf + 6.4;
$rate3-1: ($starSize * 3) + $starHalf - 6.4;
$rate3-2: ($starSize * 3) + $starHalf - 4.8;
$rate3-3: ($starSize * 3) + $starHalf - 3.2;
$rate3-4: ($starSize * 3) + $starHalf - 1.6;
$rate3-5: ($starSize * 3) + $starHalf;
$rate3-6: ($starSize * 3) + $starHalf + 1.6;
$rate3-7: ($starSize * 3) + $starHalf + 3.2;
$rate3-8: ($starSize * 3) + $starHalf + 4.8;
$rate3-9: ($starSize * 3) + $starHalf + 6.4;
$rate4-1: ($starSize * 4) + $starHalf - 6.4;
$rate4-2: ($starSize * 4) + $starHalf - 4.8;
$rate4-3: ($starSize * 4) + $starHalf - 3.2;
$rate4-4: ($starSize * 4) + $starHalf - 1.6;
$rate4-5: ($starSize * 4) + $starHalf;
$rate4-6: ($starSize * 4) + $starHalf + 1.6;
$rate4-7: ($starSize * 4) + $starHalf + 3.2;
$rate4-8: ($starSize * 4) + $starHalf + 4.8;
$rate4-9: ($starSize * 4) + $starHalf + 6.4;

$rate1: $rate1-1, $rate1-2, $rate1-3, $rate1-4, $rate1-5, $rate1-6, $rate1-7, $rate1-8, $rate1-9;
$rate2: $rate2-1, $rate2-2, $rate2-3, $rate2-4, $rate2-5, $rate2-6, $rate2-7, $rate2-8, $rate2-9;
$rate3: $rate3-1, $rate3-2, $rate3-3, $rate3-4, $rate3-5, $rate3-6, $rate3-7, $rate3-8, $rate3-9;
$rate4: $rate4-1, $rate4-2, $rate4-3, $rate4-4, $rate4-5, $rate4-6, $rate4-7, $rate4-8, $rate4-9;

$starStr: '★';
$starContent: '';

.ratingBox {
  display: flex;
  align-items: center;
  .rating {
    //Star
    &Star {
      position: relative;
      width: $starSize * 5;
      height: $starSize;
      font-size: $starSize;
      margin-right: 4px;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '★★★★★';
        height: $starSize;
        line-height: $starSize;
      }
      &:before {
        color: $gray-11;
      }
      &:after {
        color: $yellow-1;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &Star1:after {
      width: $starSize;
    }
    &Star2:after {
      width: $starSize * 2;
    }
    &Star3:after {
      width: $starSize * 3;
    }
    &Star4:after {
      width: $starSize * 4;
    }
    &Star5:after {
      width: $starSize * 5;
    }
    @for $i from 1 through length($rate1) {
      &Star1-#{$i}:after {
        width: nth($rate1, $i);
      }
    }
    @for $i from 1 through length($rate2) {
      &Star2-#{$i}:after {
        width: nth($rate2, $i);
      }
    }
    @for $i from 1 through length($rate3) {
      &Star3-#{$i}:after {
        width: nth($rate3, $i);
      }
    }
    @for $i from 1 through length($rate4) {
      &Star4-#{$i}:after {
        width: nth($rate4, $i);
      }
    }

    @for $i from 1 through 5 {
      $starContent: $starContent + $starStr;

      &Star#{$i} {
        &:before,
        &:after {
          content: $starContent;
        }
      }
    }
  }
}
