@import "@variables"; @import "@mixin";
.defaultCloseButton {
  display: flex;
  align-self: flex-start;
  position: absolute;
  width: 21px;
  height: 16px;
  cursor: pointer;
  > span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $gray-2;
    &:first-of-type {
      top: 5px;
      transform: rotate(135deg);
    }
    &:nth-of-type(2) {
      top: 5px;
      transform: rotate(-135deg);
    }
  }
}

@include screen-mobile {
  .defaultCloseButton {	
    top: 30px;	
    left: 24px;	
  }	
}