@import "@variables"; @import "@mixin";
.suggest {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  left: 0;
  background-color: $white-1;
  width: 100%;
  border: 1px solid $gray-8;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  
  &Text {
    font-size: $font-12px;
    line-height: 20px;
    padding: 16px;
    overflow-wrap: break-word;
    color: $black-2;
    &:last-child {
      margin-bottom: initial;
    }
    &:hover {
      background-color: $blue-6;
    }
    &:active {
      background-color: $blue-3;
    }
  }
}

@include screen-tablet-mobile {
  .modal {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100vw;
    height: 100%;
    padding: 15px 22px 16px;
    position: relative;
    z-index: 1;
    background-color: $blue-4;

    &Container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &CloseButton {
      right: auto;
      left: 20px;
      top: 20px;
    }

    &Title {
      text-align: center;
      margin-bottom: 32px;
      font-weight: 700;
      font-size: $font-14px;
      color: $gray-23;
    }

    &ApplyButton {
      margin-top: auto;
      display: flex;
      background-color: $theme-color;
      color: $white-1;
      font-size: $font-14px;
      font-weight: 700;
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
    }
  }

  //エリア
  .area {
    &Label {
      font-size: $font-12px;
      font-weight: 700;
      line-height: $font-17px;
      margin-bottom: 6px;
      display: block;
    }

    &InputBox {
      background-color: $white-1;
      padding: 13px 16px;
      border-radius: 10px;
      border: 1px solid $gray-21;
      position: relative;

      input[type=text] {
        transform: scale(1);
        font-size: $font-16px;
      }

      &Input {
        border: none;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        font-size: $font-16px;
  
        &::placeholder {
          color: $gray-22;
        }
      }
    }

    &Choice {
      &List {
        margin-top: 16px;
      }

      &Item {
        border-bottom: 1px solid $gray-10;

        &NoResult {
          text-align: center;
        }
      }

      &Button {
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-column-gap: 10px;
        cursor: pointer;
        padding: 12px 0;
        align-items: center;
        width: 100%;
      }

      &Icon {
        &Current {
          width: 20px;
        }
      }

      &Text {
        font-size: $font-14px;
        line-height: $font-20px;
        text-align: left;
      }
    }
  }

  .suggest {
    top: calc(100% + 8px);
  }
}