@import "@variables"; @import "@mixin";
@include screen-tablet-mobile {
  .modal {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100vw;
    height: 100%;
    padding: 15px 22px 16px;
    position: relative;
    z-index: 1;
    background-color: $blue-4;

    &Container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &CloseButton {
      right: auto;
      left: 20px;
      top: 20px;
    }

    &Title {
      text-align: center;
      margin-bottom: 32px;
      font-weight: 700;
      font-size: $font-14px;
      color: $gray-23;
    }

    &ApplyButton {
      margin-top: auto;
      display: flex;
      background-color: $theme-color;
      color: $white-1;
      font-size: $font-14px;
      font-weight: 700;
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
    }
  }

  //預け入れ - 日程
  .date {
    &Label {
      font-size: $font-12px;
      font-weight: 700;
      line-height: $font-17px;
      margin-bottom: 6px;
      display: block;
    }

    &InputBox {
      background-color: $white-1;
      padding: 13px 16px;
      border-radius: 10px;
      border: 1px solid $gray-21;
      position: relative;

      &Placeholder {
        color: $gray-22;
        font-size: $font-14px;
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
        cursor: pointer;

        &Date {
          background-image: url('/calendar_icon_navy_dark.svg');
          background-repeat: no-repeat;
          background-position: right 0 center;
          background-size: 20px;
        }
      }

      &DateCalendar {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        z-index: 1;
        width: 100%;
      }

      &Result {
        &Date {
          display: flex;
          grid-column-gap: 4px;
          align-items: center;
  
          &Text {
            font-size: $font-14px;
          }
        }  
      }
    }

    &Box {
      margin-bottom: 12px;
      position: relative;
    }

    &Time {
      &Select {
        transform: scale(1);
        width: 100%;
        box-sizing: border-box;
        height: 48px;
        padding: 0 12px;
        font-size: $font-16px;
        color: $black-3;
        border-radius: 8px;
        border: 1px solid $gray-21;
        appearance: none;
        background-image: url('/arrow_bottom_gray.svg');
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-size: 12px 7px;
      }
  
      &Description {
        color: $gray-10;
        font-size: $font-14px;
        margin-top: 6px;
      }

      &Contents {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
      }
    }
  }

  //バッグサイズ
  .luggage {
    display: grid;
    grid-template-columns: 16px auto 88px;
    grid-column-gap: 12px;
    align-items: center;

    &Icon {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }

    &Label {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      font-size: $font-14px;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 4px;
    }

    &Description {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      font-size: $font-14px;
      line-height: 1;
    }

    &Stepper {
      grid-column: 3;
      grid-row: 1 / 3;

      &Button {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: $blue-11;

        &:disabled {
          background-color: $gray-16;
        }
      }
    }

    &Contents {
      display: grid;
      grid-row-gap: 30px;
      margin-top: 20px;
    }
  }

  //filter
  .filter {
    &Content {
      text-align: right;
      margin-top: 20px;
    }

    &Button {
      background-color: transparent;
      cursor: pointer;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;

      &Icon {
        margin-right: 8px;
      }

      &Text {
        font-size: $font-14px;
        font-weight: 700;
        color: $theme-color;
      }
    }
  }
}