@import "@variables"; @import "@mixin";
.prev {
  position: absolute;
  top: 30px;
  left: 38px;
  z-index: 2;
  font-size: 100px;
  position: relative;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid $black-2;
  border-left: 2px solid $black-2;
  transform: rotate(45deg);
  cursor: pointer;
}

.next {
  position: absolute;
  top: 30px;
  right: 41px;
  z-index: 2;
  font-size: 100px;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid $black-2;
  border-left: 2px solid $black-2;
  transform: rotate(-135deg);
  cursor: pointer;
}

//預け入れ - 受け取り時間
.timePicker {
  &Container {
    padding: 0 28px 16px 28px;
  }

  &Times {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  &Time {
    &Title {
      font-weight: 700;
      display: block;
      margin-bottom: 4px;
      font-size: $font-12px;
      color: $gray-23;
    }

    &Select {
      width: 100%;
      box-sizing: border-box;
      height: 48px;
      padding: 0 12px;
      font-size: $font-16px;
      color: $black-3;
      border-radius: 8px;
      border: 1px solid $gray-21;
      appearance: none;
      background-image: url('/arrow_bottom_gray.svg');
      background-position: right 20px center;
      background-repeat: no-repeat;
      background-size: 12px 7px;
    }
  }

  &Description {
    color: $gray-10;
    font-size: $font-14px;
    margin-top: 6px;
  }
}