@import "@variables"; @import "@mixin";
.search {
  box-sizing: border-box;

  &Form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 48px;
    grid-column-gap: 12px;
    background-color: $white-1;
    padding: 13px 16px;
    border-radius: 10px;
    border: 1px solid $gray-21;
  }

  &InputBox {
    border-right: 1px solid $gray-21;

    &Label {
      display: block;
      margin: 2px 0;

      &Text {
        font-size: $font-12px;
        font-weight: 700;
        line-height: $font-17px;
      }

      &Icon {
        display: none;
      }
    }

    &Input {
      border: none;
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      font-size: $font-14px;
      line-height: 150%;

      &::placeholder {
        color: $gray-22;
      }
    }

    &Placeholder {
      color: $gray-22;
      font-size: $font-14px;
    }

    &Focused {
      .search {
        &InputBox{
          &Label {
            color: $blue-11;
          }
        }
      }
    }

    &Result {
      &Date {
        display: flex;
        grid-column-gap: 4px;
        align-items: center;

        &Text {
          font-size: $font-14px;
        }
      }

      &Luggage {
        display: flex;
        grid-column-gap: 8px;

        &Counter {
          display: inline-flex;
          align-items: center;
          font-size: $font-12px;

          &Icon {
            margin-right: 2px;

            &Bag {
              width: 16px;
            }

            &SuitCase {
              width: 16px;
            }
          }
        }
      }
    }

    &Area {
      position: relative;
    }

    &Date {
      position: relative;

      &Calendar {
        position: absolute;
        top: 72px;
        left: -16px;
        z-index: 2;

      }
    }

    &Luggage {
      position: relative;
    }

    &DateLuggage {
      display: none;
    }

    &Button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &Btn {
    cursor: pointer;
    display: inline-flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $theme-color;

    &Image {
      width: 19px;
      height: 19px;
    }

    &Text {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $blue-10;
    }
  }

  &Horizontal {
    max-width: 1090px;

    .search {
      &Form {
        grid-template-columns: 1fr 1fr 1fr 32px;
        padding: 8px 16px;
      }

      &InputBox {
        display: grid;
        padding-right: 12px;

        &Label {
          margin: 0;
          font-size: $font-14px;
          line-height: 150%;
        }

        &Date {
          &Calendar {
            top: 48px;    
          }
        }

        &Luggage {
          .luggageContainer {
            top: 48px;
          }
        }

        &DateLuggage {
          display: none;
        }
      }

      &BtnIcon {
        width: 32px;
        height: 32px;
    
        &Image {
          width: 12px;
          height: 12px;
        }
      }

      &Btn {
        width: 32px;
        height: 32px;

        &Image {
          width: 13px;
          height: 13px;
        }    
      }
    }
  }
}

.suggest {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  left: 0;
  background-color: $white-1;
  width: 100%;
  border: 1px solid $gray-8;
  border-radius: 8px;
  z-index: 2;
  overflow: hidden;
  
  &Text {
    font-size: $font-12px;
    line-height: 20px;
    padding: 16px;
    overflow-wrap: break-word;
    color: $black-2;
    &:last-child {
      margin-bottom: initial;
    }
    &:hover {
      background-color: $blue-6;
    }
    &:active {
      background-color: $blue-3;
    }
  }
}

.luggageContainer {
  position: absolute;
  top: 74px;
  left: -16px;
  width: 327px;
  background-color: $white-1;
  padding: 30px;
  border-radius: 10px;
  z-index: 2;
  border: 1px solid $gray-6;

  &BagSize,
  &SuiteCaseSize {
    display: flex;
    flex-direction: column;
  }
  &BagSize {
    margin-bottom: 22px;
  }

  &Counter {
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    &Label {
      line-height: 24px;
      color: $black-2;
      font-weight: 700;
      margin-right: 10px;
    }
  }

  &Description {
    color: $gray-6;
    font-size: $font-12px;
    line-height: 20px;
    font-weight: 700;
  }
  
}

@include screen-tablet-mobile {
  .search {
    &Form {
      grid-column-gap: 16px;
      grid-template-columns: auto;
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      border: none;
      grid-row-gap: 8px;
    }

    &InputBox {
      grid-template-columns: 20px 1fr;
      border-right: none;
      background-color: $white-1;
      padding: 14px 12px;
      border-radius: 10px;
      border: 1px solid $gray-21;
      display: flex;
      align-items: center;
      grid-column-gap: 10px;
      overflow: hidden;

      &Label {
        margin: 0;
        display: inline-flex;
        min-width: 20px;

        &Text {
          display: none;
        }
  
        &Icon {
          display: inline;

        }
      }  

      input[type=text] {
        transform: scale(1);
        font-size: $font-16px;
      }

      &Placeholder {
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &Date, 
      &Luggage {
        display: none;
      }

      &Result {
        display: flex;
        grid-gap: 12px;

        &Area {
          &Text {
            font-size: $font-14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &Date {
          display: flex;
          grid-column-gap: 4px;
          align-items: center;
  
          &Text {
            font-size: $font-14px;
          }
        }
  
        &Luggage {
          display: flex;
          grid-column-gap: 8px;
  
          &Counter {
            display: inline-flex;
            align-items: center;
            font-size: $font-12px;
  
            &Icon {
              margin-right: 0;
  
              &Bag {
                width: 16px;
              }
  
              &SuitCase {
                width: 16px;
              }
            }
          }
        }
      }

      &DateLuggage {
        display: flex;
      }
    }

    &Btn {
      width: 100%;
      height: 48px;
      border-radius: 100px;
      background-color: $theme-color;
  
      &Image {
        display: none;
      }

      &Text {
        display: inline;
        color: $white-1;
        font-size: $font-14px;
        font-weight: 700;
      }

      &:disabled {
        background-color: $blue-10;
      }
    }

    &Horizontal {
      .search {
        &Form {
          grid-template-columns: auto;
          grid-row-gap: 8px;
          padding: 0;
          border: none;
        }
  
        &InputBox {
          &Date, 
          &Luggage {
            display: none;
          }

          &DateLuggage {
            display: flex;
          }
        }
  
        &Btn {
          display: none;
        }
      }
    }
  
  }
}